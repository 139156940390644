import React,{Component} from "react";
import Layout from "../components/Layout";
import SEO from '../components/seo';
import FormDemonstracao from '../components/Forms/FormDemonstracao';
import Footer from "../components/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck,faDownload } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';
import { Link } from "gatsby";

import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
const MySwal = withReactContent(Swal)


const workingEmailValidator = email => 
    !['gmail', 'hotmail', 'yahoo', 'outlook'].includes(email.split('@')[1].split('.')[0]);


class ConteudoPage extends Component{
  constructor(){
    super();
    this.state = {
          
        id: '',
        data:null,

        nome: '',
        email: '',
        show: false,
        baixado: false
    } 
  } 
 
  componentDidMount() {
    this.buscarDados();
    //this.setState({data:JSON.parse('{"data":{"id":"81209754-ee10-44ec-8b22-3c496c1e9aa0","date_created":"2024-02-02T17:51:19.476Z","titulo":"Titulo de testes","descricao":"<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupithis.state.datat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>","arquivo":"b99344e0-7a3d-4dbb-be4f-2d74b64139e4","imagem":"82f45eeb-15ca-419c-9572-55787f286fdf","nome_arquivo":"Arquivo de testes.pdf"}}')['data']});
  }

  buscarDados = () =>{ 
    if(typeof document !== `undefined`){
      let params = new URLSearchParams(document.location.search); 
    
      let id = params.get('item');

      this.setState({id:id});

      axios.get(`https://cms.shelfin.com.br/items/Conteudos/${id}`,{ 
        headers:{
          "Content-type": "application/json",
          'Access-Control-Allow-Origin':'*',
          'Authorization': 'Bearer rq12SqeeXda-Rhj_ZCBdaBwjWXvNJZH5'
        }
      }).then((response) => {
        this.setState({data:response.data.data});
      }).catch((error) => {
        //this.state.data = null;
        console.log("Um erro inesperado aconteceu!");
        this.redirect();
      });
    }
    return ;
  }

  redirect(){
    window.location.href = '/';
  }

  handleShow =()=> {
    this.setState({show:!this.state.show});
  }

  send = () => {

    if(this.state.nome == "" || this.state.email == "") return;

    if(this.state.email.split("@").length <= 1){
      MySwal.fire({
        title: 'Por favor preencha um email valido!',
        icon: 'warning'
      });
      return;
    }

    if(this.state.email == '' || workingEmailValidator(this.state.email) == false){
      MySwal.fire({
        title: 'Por favor preencha um email empresarial!',
        icon: 'warning'
      });
      return;
    }
  
    axios.post(`https://cms.shelfin.com.br/items/Emails`,
        {
          nome: this.state.nome,
          email: this.state.email
        }
        ,{ 
        headers:{
          "Content-type": "application/json",
          'Access-Control-Allow-Origin':'*',
          'Authorization': 'Bearer rq12SqeeXda-Rhj_ZCBdaBwjWXvNJZH5'
        }
      }).then((response) => { 
        this.setState({baixado:true}); 
        setTimeout(() => {
          if(typeof document !== `undefined`) {
            const a = document.getElementById("A"+this.state.id);
            a.click();
          }
        }, 600);
      }).catch((error) => {
        console.log("Um erro inesperado aconteceu!");
      });
  }

  render(){
  return this.state.data != null ? (
    <div className="w-full min-h-screen flex flex-col">
      <SEO 
          title={`${this.state.data.titulo} | Conteúdos`}
          description={this.state.data.descricao}
      />
      <a className="z-40 hover:bg-green-600 flex flex-row items-center p-2 px-4 uppercase space-x-4 text-lg fixed bottom-4 right-4 bg-green-500 text-white rounded-md" href="https://wa.me/554136384128?text=Ol%C3%A1%2C%20poderia%20me%20passar%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20plataforma%20Shelfin%3F" >
			  <FontAwesomeIcon icon={faWhatsapp} size="2x"/>
			  <div className="">Mande-nos uma mensagem!</div>	
		  </a>

      <div className="w-full flex items-center justify-center">
        <div className="w-2/4 flex flex-col space-y-8">
          
          <div className="h-32"></div>
          <div className="w-full flex items-center justify-between">
              <div className="text-3xl text-gray-700">Conteúdos</div>
              <Link to="/"><img className="h-14" src="/images/logo_escura.png"/></Link>
          </div>
          <div dangerouslySetInnerHTML={{ __html:this.state.data.descricao}}></div>
          <a href="#baixar" className="text-blue-500 hover:underline">Ir para o download do Documento</a>
          {/*<div className="w-full flex items-center justify-center py-20">
            <div className="flex flex-col space-y-2">
              <div className="w-2 h-2 rounded-full bg-gray-500"></div>
              <div className="w-2 h-2 rounded-full bg-gray-500"></div>
              <div className="w-2 h-2 rounded-full bg-gray-500"></div>
            </div>
          </div>*/}
          
          <h1 className="text-gray-700 text-3xl font-bold">{this.state.data.titulo}</h1> 
          <img src={`https://cms.shelfin.com.br/assets/${this.state.data.imagem}`} className="w-full object-cover rounded-3xl"/> 
          {
            this.state.baixado == false ? (
              <a onClick={this.handleShow} id="baixar" className="cursor-pointer w-full flex flex-row text-gray-700 px-8 items-center justify-between bg-pink-100 border-2 border-pink-500 hover:bg-pink-300 transition duration-150 ease-in-out rounded-xl p-4">
                <div className="flex flex-row space-x-2 items-center">
                  <FontAwesomeIcon icon={faDownload} size="2x"/>
                  <div className="text-xl text-gray-700 text-xl">{this.state.data.nome_arquivo}</div> 
                </div>
                <div>Clique para baixar!</div>
              </a>
            ) : (
              <div className="w-full p-4 rounded-xl border-green-300 bg-green-200 flex items-center justify-center">
                <div>Download iniciado! <a id={"A"+this.state.id} href={`https://cms.shelfin.com.br/assets/${this.state.data.arquivo}?download`} download={this.state.data.nome_arquivo} className="hover:underline">Clique aqui caso não tenha baixado automaticamente!</a></div>
              </div> 
            )
          }
          { this.state.show && this.state.baixado == false ? (
            <div className="flex flex-col space-y-2">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 bg-gray-200 p-8 rounded-xl">
              <div className="flex flex-col space-y-2">
                <div className="">Seu nome</div>
                <input type="text" onChange={evt => this.setState({nome:evt.target.value})} maxLength="255" className="w-full p-4 rounded-md bg-white border-2" placeholder="ex: José" value={this.state.nome} />
              </div>
              <div className="flex flex-col space-y-2">
                <div className="">Seu email</div>
                <input type="text" onChange={evt => this.setState({email:evt.target.value})} maxLength="255" className="w-full p-4 rounded-md bg-white border-2" placeholder="seu@email.com" value={this.state.email} />
              </div>
            </div>
            <button onClick={this.send} disabled={this.state.nome == "" || this.state.email == ""}  className={ (this.state.nome == "" || this.state.email == "" ? "opacity-50" : "opacity-100" ) +" transition duration-150 ease-in-out  hover:bg-green-600 bg-green-500 p-2 px-4 text-xl text-white rounded-xl"}>Baixar!</button>
          </div> 
          ):(<div></div>)} 
          <div className="h-32"></div>
        </div>
      </div>
      <Footer/>
    </div> 
  ) : null;
  }
}
export default ConteudoPage
